import dayjs from "dayjs";

const columns = [
  {
    field: "id",
    headerName: "ESTU ID",
    width: 80,
    download: true,
    renderCell: (params) => {
      return (
        <p className="text-center" title="ESTU ID">
          {params?.row?.id || "---"}
        </p>
      );
    },
  },
  { field: "name", headerName: "Name", width: 150, download: true },
  { field: "email", headerName: "Email", width: 200, download: true },
  {
    field: "collegeName",
    headerName: "School Name",
    width: 150,
    download: true,
  },
  { field: "mobileNo", headerName: "Phone", width: 120, download: true },
  {
    field: "createdAt",
    headerName: "Date Time (EST)",
    width: 180,
    download: true,
    completeDate: true,
    renderCell: (params) => {
      return (
        <p className="text-center" title="yyyy-mm-dd:hh:mm:ss time format EST">
          {dayjs(params?.row?.createdAt)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD:HH:mm:ss") || "---"}
        </p>
      );
    },
  },
  {
    field: "savingAccountNumber",
    headerName: "Checking Account No.",
    csvHeaderName: "checkingAccountNumber",
    width: 180,
    download: true,
    renderCell: (params) => {
      <p className="text-center">
        {params?.row?.savingAccountNumber
          ? params?.row?.savingAccountNumber
          : "---"}
      </p>;
    },
  },
  {
    field: "clientId",
    headerName: "Client Id",
    width: 80,
    download: true,
    renderCell: (params) => {
      <p className="text-center">{params?.row?.clientId || "---"}</p>;
    },
  },
  {
    field: "cardId",
    headerName: "Card Id",
    width: 80,
    download: true,
    renderCell: (params) => {
      <p className="text-center">{params?.row?.cardId || "---"}</p>;
    },
  },
  {
    field: "bankOnboardingDate",
    headerName: "Bank Onboarding Date",
    width: 180,
    download: true,
    completeDate: true,
    renderCell: (params) => {
      return (
        <p className="text-center w-full" title="yyyy-mm-ddtime format">
          {params?.row?.bankOnboardingDate
            ? dayjs(params?.row?.bankOnboardingDate)
                // ?.tz("America/New_York")
                .format("YYYY-MM-DD")
            : "---"}
        </p>
      );
    },
  },
  {
    field: "classification",
    headerName: "Classification",
    width: 130,
    download: true,
  },
];

const tabs = [
  {
    text: "All",
    value: "all",
    title: "View data for all customers",
  },
  {
    text: "Not Onboarded",
    value: "nonkyc",
    title: "Users not onboarded or failed KYC verification",
  },
  {
    text: "Freemium",
    value: "freemium",
    title:
      "Users onboarded without completing KYC (SKIP BUTTON CLICK ON INITIAL ONBOARDING)",
  },

  {
    text: "Freemium (KYC Failed / Awaiting)",
    value: "kycfreemium",
    title: "Users who skipped KYC or whose KYC is pending/failed",
  },
  {
    text: "Banking",
    value: "kyc",
    title: "Fully onboarded users with access to financial services",
  },
];

export { columns, tabs };
