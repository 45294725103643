import React, { useState, useEffect, useRef } from "react";
import style from "./adsmanager.module.css";
import styleform from "./adsmanager/style.module.css";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import CampaignPreviewComponent from "./dialogs/CampaignPreview";
import {
  createAd,
  getAdminAds,
  GetAdsManagerData,
  toggleActiveStatusAdsAPI,
} from "../../api/api";
import SoftDialog from "../../components/molecules/dialogs";
import { Button } from "../../components";
import { FormConfigForAds } from "./adsmanager/formConfig";
import GenericFormComponent from "../../components/Form/Form";
import { CheckIcon, PhotoIcon } from "../../assets/svgs/svgs";
import { ImageUpload } from "./discountCoupons/imageUpload";
import { toast } from "react-toastify";
import { ChevronRight, RampRightOutlined } from "@mui/icons-material";
import { formatDateToYYYYMMDD } from "../../utils/func";
import dayjs from "dayjs";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";

export default function AdsManagerDemo() {
  const [active, setActive] = useState(0);
  const [rows, setRows] = useState([]);
  const [showCampagin, setShowCampagin] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(0);
  const [step, setStep] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    profilePhoto: "",
  });
  const [showPreview, setShowPreview] = useState(false);
  const handlePreview = () => {
    setShowPreview(true);
  };

  const columns = [
    {
      field: "title",
      headerName: "Campaign Name",
      width: 180,
      renderCell: (params) => {
        const data = params.row;
        const imageUrl = data.media;

        return (
          <div className={`flex gap-4 items-center justify-start w-full`}>
            <div className={style.campaginName_image}>
              <img src={`${process.env.REACT_APP_IMAGE_BASEURL}${imageUrl}`} />
            </div>
            <div className={style.campaginName_title}>{data.title}</div>
          </div>
        );
      },
    },
    {
      field: "redirectUrl",
      headerName: "Website Link",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => {
        const data = params.row;
        return (
          <div
            className={`flex gap-2 items-center justify-center w-full h-full`}
          >
            <Button
              bgColor="#2CCCD3"
              color="white"
              borderRadius="10px"
              icon={null}
              text={"View"}
              onClick={(_) => {
                setSelectedCampaign(data);
                setShowCampagin(true);
              }}
            />
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const data = params.row;
        return (
          <div
            className={`flex gap-2 items-center h-full justify-center w-full`}
          >
            <Button
              bgColor={params?.row?.isActive ? "#79D32C" : "#dd2222"}
              color="white"
              borderRadius="10px"
              icon={null}
              text={params?.row?.isActive ? "Active" : "DeActive"}
              onClick={(_) => {
                toggleActiveStatus(
                  params?.row,
                  params?.row?.isActive ? false : true
                );
              }}
            />
          </div>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date (EST)",
      width: 130,
      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd time format EST">
          {dayjs(params?.row?.startDate)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD")}
        </p>
      ),
    },
    {
      field: "endDate",
      headerName: "End Date (EST)",
      width: 130,

      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd time format EST">
          {dayjs(params?.row?.endDate)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD")}
        </p>
      ),
    },
  ];
  const handleCloseAddPreview = () => {
    setShowPreview(false);
    // setShowForm(false);
  };
  const toggleActiveStatus = async (data, newStatus) => {
    // console.log(data);
    try {
      const res = await toggleActiveStatusAdsAPI(data, newStatus);
      // console.log(res);
      if (res?.status) {
        if (newStatus) {
          toast.success(
            `Status changed to ${
              newStatus ? "Active" : "Deactivate"
            } successfully!`
          );
        } else {
          toast.info(
            `Status changed to ${
              newStatus ? "Active" : "Deactivate"
            } successfully!`
          );
        }
        fetchData();
      } else {
        toast.error("Failed to update status!");
      }
    } catch (err) {
      console.error(err);
      toast?.error(err);
    }
  };
  const fetchData = async () => {
    try {
      const res = await getAdminAds();
      // console.log(res);
      setRows(res.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [showCampagin]);
  useEffect(() => {
    if (step === 1) {
      fetchData();
    }
  }, [step]);

  return (
    <div className="flex gap-4 md:flex-col">
      <h2 className="px-4 text-[#344767] font-roboto font-bold text-2xl leading-[26px]">
        Ads Manager
      </h2>
      <div className={style.container}>
        <CampaignPreviewComponent
          open={showCampagin}
          data={selectedCampaign}
          handleClose={(_) => setShowCampagin(false)}
        />

        <SoftDialog
          title="Ad Preview"
          open={showPreview}
          handleClose={handleCloseAddPreview}
        >
          <AdPreviewModal
            open={showPreview}
            data={formData}
            handleClose={handleCloseAddPreview}
            step={step}
            setShowPreview={setShowPreview}
            setStep={setStep}
            refresh={fetchData}
          />
        </SoftDialog>

        <SoftDialog
          title={"Create Ad"}
          open={showForm}
          handleClose={(_) => {
            setStep(0);
            setShowForm(false);
          }}
        >
          {step === 0 ? (
            <FormComponent
              setStep={setStep}
              step={step}
              formData={formData}
              setFormData={setFormData}
              setShowPreview={setShowPreview}
            />
          ) : (
            <SuccessFulllStep />
          )}
        </SoftDialog>
        <div
          className={`${style.topFilterSection} flex flex-row justify-between `}
        >
          <div className={`flex flex-row align-center gap-2`}>
            <button
              onClick={(_) => setActive(0)}
              className={`${style.buttonTab} ${
                active === 0 ? style.active : ""
              }`}
            >
              All{" "}
              <span
                className={`${style.badge} ${
                  active === 0 ? style.activeBadge : ""
                }`}
              >
                {rows.length}
              </span>{" "}
            </button>
          </div>
          <div className="flex flex-row items-center gap-2 ">
            <Button
              onClick={(_) => {
                setShowForm(true);
                setFormData({});
              }}
              bgColor="#2CCCD3"
              color="white"
              borderRadius="10px"
              icon={null}
              text={"Create Ad"}
            />
          </div>
        </div>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "white",
            flex: 1,
          }}
        >
          <CDataGrid rows={rows} columns={columns} pagination />
        </Box>
      </div>
    </div>
  );
}

const FormComponent = ({ formData, setFormData, setShowPreview, setStep }) => {
  const fileInputRef = useRef(null);
  const handlePreview = () => {
    setShowPreview(true);
  };
  const validationsEvent = (e) => {
    // Validations
    let isValid = true;
    FormConfigForAds.forEach((field) => {
      if (field.required && !formData[field.name]) {
        isValid = false;
      }
    });
    // console.log(formData);

    if (
      formData?.startDate &&
      formData?.endDate &&
      formData?.startDate > formData?.endDate
    ) {
      toast.error("Start date should be less than end date!");
      return;
    }
    if (formData.profilePhoto === "" || !formData.profilePhoto) {
      isValid = false;
    }
    if (isValid) {
      handlePreview();
    } else {
      toast.error("Please fill all the required fields");
    }
  };

  return (
    <div className={`flex flex-col gap-2 align-start mt-[15px]`}>
      <div className={`flex flex-col align-start`}>
        <div
          className={`flex flex-col px-3 h-max`}
          onClick={(_) => fileInputRef.current.click()}
        >
          <div className={styleform.uploadIcon}>
            {formData.profilePhoto !== "" && formData.profilePhoto ? (
              <img
                style={{ width: 100, height: 100 }}
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${formData.profilePhoto}`}
              />
            ) : (
              <PhotoIcon />
            )}
          </div>
          <div className={styleform.uploadIcontext}>Upload from computer</div>
        </div>
        <GenericFormComponent
          formConfig={FormConfigForAds}
          formData={formData}
          setFormData={setFormData}
          handleSubmit={validationsEvent}
          width={"800px"}
          submitText={"Preview"}
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => ImageUpload(e, setFormData, "profilePhoto")}
        />
      </div>
    </div>
  );
};

const SuccessFulllStep = () => {
  return (
    <div
      className={`flex flex-col p-5 gap-10 w-100`}
      style={{ alignItems: "center" }}
    >
      <div className={"w-[100px] h-[100px] mx-3"}>
        <CheckIcon />
      </div>
      <div className={style.successtext}>Ad created successfully!</div>
    </div>
  );
};

const AdPreviewModal = ({
  open,
  step,
  setStep,
  setShowPreview,
  data,
  handleClose,
  refresh,
}) => {
  const handleSubmit = async () => {
    const res = await createAd({
      ...data,
      media: data.profilePhoto,
    });

    // console.log(res, "bbbb");
    if (res.status === "success") {
      setShowPreview(false);
      setStep((prev) => prev + 1);
      setFormData({});
      await refresh();
    } else {
      toast.error(res.message || "Something went wrong");
    }
  };
  return (
    <div className="flex flex-col gap-4 min-w-[200px]  max-h-[70vh]">
      <div className="relative w-full max-w-[400px] aspect-square">
        <img
          src={`${process.env.REACT_APP_IMAGE_BASEURL}${data.profilePhoto}`}
          alt="Ad Image"
          className="w-full rounded-md  max-h-[300px]"
        />

        <div className="absolute inset-0 bg-gradient-to-r from-black/50 via-transparent to-black/50 rounded-md"></div>
      </div>
      <div className="flex flex-col">
        <a
          href={
            data?.redirectUrl?.startsWith("http")
              ? data.redirectUrl
              : `https://${data.redirectUrl}`
          }
          title={data?.redirectUrl}
          target="_blank"
          className="no-underline bg-[#2cccd3] p-2 text-white rounded-lg flex flex-row items-center justify-between"
        >
          <p className="truncate whitespace-nowrap">
            <strong>{data.cta}</strong>
          </p>
          <ChevronRight className="text-white" />
        </a>
      </div>{" "}
      <div className=" max-w-[300px]">
        <h2 className="text-lg font-semibold mt-0  duration-300 rounded-md hover:scale-105">
          {data.title}
        </h2>
        <p className="text-slate-500  duration-300 rounded-md hover:scale-105">
          {data?.description}
        </p>
      </div>
      <div className="flex justify-end mt-4">
        <Button
          bgColor="#2CCCD3"
          color="white"
          borderRadius="10px"
          icon={null}
          text={"Submit Ad"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
