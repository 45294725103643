import React, { useEffect, useState, useRef } from "react";
import style from "../allowedUsers/style.module.css";
import Box from "@mui/material/Box";
import Loader from "./../../components/atoms/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { getNonSSNDetails } from "../../api/api";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";

const columns = [
  { field: "id", headerName: "id", width: 50, hide: true },
  { field: "name", headerName: "Name", width: 200 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "mobileNo", headerName: "Mobile No", width: 150 },
  {
    field: "createdAt",
    headerName: "Created At (EST)",
    width: 200,

    renderCell: (params) => (
      <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
        {dayjs(params?.row?.createdAt)
          ?.tz("America/New_York")
          .format("YYYY-MM-DD:HH:mm:ss")}
      </p>
    ),
  },
];

export default function NonSSNStudent() {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const [statusFilter, setStatusFilter] = useState(
    location?.state?.currStatus || ""
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getNonSSNDetails({ kyc: statusFilter });

        setLoading(false);
        setRowData(data?.data?.internationalStudents || []);
      } catch (err) {
        console.log("err", err);
        toast.error(err.response?.data?.error);
        setLoading(false);
      }
    };

    fetchData();
  }, [statusFilter]);

  useEffect(() => {
    const { state } = location;

    if (state && state.activeTab) {
      setStatusFilter(state?.currStatus);
    }
  }, [location]);

  return (
    <div className={style.container}>
      <div className={"flex w-full items-center justify-between mb-4"}>
        <div className={style.title}>International Student Details</div>

        <div className="flex flex-row items-center gap-2 border-b pb-2">
          <label
            htmlFor="statusFilter"
            className="text-sm font-medium text-gray-700"
          >
            Filter by Customer:
          </label>
          <select
            id="statusFilter"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="border border-gray-300 rounded-md px-2 py-1 text-sm cursor-pointer"
          >
            <option value="">All</option>
            <option value="ACCEPT">Approved</option>
            <option value="PENDING">Pending</option>
            <option value="REJECTI20">Rejected</option>
          </select>
        </div>
      </div>

      <div className={`flex flex-column gap-2 align-center h-auto`}>
        <Box sx={{ height: "83vh", width: "100%" }}>
          {rowData?.length === 0 && loading ? (
            <Loader />
          ) : rowData?.length > 0 ? (
            <CDataGrid
              rows={rowData}
              columns={columns}
              pagination
              loading={loading}
            />
          ) : null}
        </Box>
      </div>
    </div>
  );
}
