import { useEffect, useState } from "react";
import { getReferralsListAPI } from "../../api/api";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Loader } from "../../components";
import dayjs from "dayjs";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";

const ReferralsPage = () => {
  const [loading, setLoading] = useState(false);
  const [cData, setCData] = useState([]);

  const getAPI = async () => {
    setLoading(true);

    try {
      const res = await getReferralsListAPI();

      if (res.status === "success") {
        setCData(res?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "referrer_name", headerName: "Referrer Name", width: 150 },

    {
      field: "ref_code",
      headerName: "referral code",
      width: 100,
    },
    { field: "customer_name", headerName: "Customer Name", width: 120 },
    {
      field: "date_of_join",
      headerName: "Joining Date (EST)",

      renderCell: (params) => (
        <p className="text-center" title="yyyy-mm-dd time format EST">
          {dayjs(params?.row?.date_of_join)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD") || "---"}
        </p>
      ),
      width: 140,
    },
  ];

  return (
    // <div className="mx-auto w-full pt-4 max-h-screen overflow-auto h-[99vh]">
    <div className="mx-auto w-full pt-4 h-full">
      <div className="flex gap-4 md:flex-col mb-4">
        <h2 className="text-[#344767] font-roboto font-bold text-2xl leading-[26px]">
          Referrals Management
        </h2>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div>
            Loading... <Loader />
          </div>
        </div>
      ) : (
        <Box
          sx={{
            height: "90%",
            width: "100%",
            backgroundColor: "white",
            flex: 1,
            borderRadius: "12px",
          }}
        >
          <CDataGrid
            rows={cData.map((item, index) => {
              if (!item.hasOwnProperty("id")) {
                return { ...item, id: index };
              }
              return item;
            })}
            columns={columns}
            pagination
            loading={loading}
          />
        </Box>
      )}
    </div>
  );
};
export default ReferralsPage;
