import React, { useEffect, useState, useRef } from "react";
import style from "../allowedUsers/style.module.css";
import Box from "@mui/material/Box";
import Loader from "./../../components/atoms/Loader";
import { DataGrid } from "@mui/x-data-grid";
import EditRowComponent from "./editRow";
import SoftDialog from "../../components/molecules/dialogs";
import { getBankDetails } from "../../api/api";
import dayjs from "dayjs";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";

const columns = [
  // { field: "id", headerName: "id", width: 50, hide: true },
  {
    field: "userId",
    headerName: "userId",
    width: 100,
    renderCell: (params) => {
      return <p className="text-start">{params.row?.userId || "---"}</p>;
    },
  },
  {
    field: "name",
    headerName: "Name",
    renderCell: (params) => params.row?.name || "---",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    renderCell: (params) => params.row?.email || "---",
    width: 180,
  },
  {
    field: "mobileNo",
    headerName: "Mobile No",
    renderCell: (params) => params.row?.mobileNo || "---",
    width: 150,
  },

  {
    field: "cardId",
    headerName: "Card Id",
    width: 70,
    type: "number",
    renderCell: (params) => (
      <p className="text-center">{params.row?.cardId || "---"}</p>
    ),
  },
  {
    field: "clientId",
    headerName: "Client Id",
    width: 90,
    type: "number",
    renderCell: (params) => (
      <p className="text-center">{params.row?.clientId || "---"}</p>
    ),
  },
  {
    field: "savingAccountId",
    headerName: "Saving Acc. Id",
    width: 120,
    renderCell: (params) => (
      <p className="text-center">{params.row?.savingAccountId || "---"}</p>
    ),
  },
  {
    field: "savingAccountNumber",
    headerName: "Saving Account Number",
    width: 180,
    renderCell: (params) => (
      <p className="text-center">{params.row?.savingAccountNumber || "---"}</p>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created At (EST)",
    width: 200,

    renderCell: (params) => (
      <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
        {dayjs(params?.row?.createdAt)
          ?.tz("America/New_York")
          .format("YYYY-MM-DD:HH:mm:ss")}
      </p>
    ),
  },
];

export default function Mbanq() {
  const [rowData, setRowData] = useState(null);
  const [showEditRowModal, setShowEditRowModal] = useState(false);
  const [updateRows, setUpdateRows] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    userId: "",
    savingAccountId: "",
    cardId: "",
    clientId: "",
    savingAccountNumber: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getBankDetails();
        console.log("data", data?.data);
        const filteredData = data?.data?.bankUserDetails?.map((item, idx) => {
          return {
            id: idx + 1,
            email: item?.email,
            name: item?.name,
            mobileNo: item?.mobileNo,
            userId: item?.userId,
            savingAccountId: item?.savingAccountId ? item?.savingAccountId : "",
            cardId: item?.cardId ? item?.cardId : "",
            clientId: item?.clientId ? item?.clientId : "",
            createdAt: item?.createdAt ? item?.createdAt : "",
            savingAccountNumber: item?.savingAccountNumber
              ? item?.savingAccountNumber
              : "",
          };
        });

        setRowData(filteredData);
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [updateRows]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.title}>Mbanq User Details</div>
      </div>

      {loading ? (
        <div className={`flex flex-row justify-center align-center`}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={`flex flex-column gap-2 align-center h-auto`}>
            <Box sx={{ height: "92vh", width: "100%" }}>
              {rowData?.length > 0 ? (
                <CDataGrid
                  rows={rowData}
                  columns={columns}
                  pagination
                  onRowDoubleClick={(params) => {
                    console.log("params", params.row);
                    setFormData(params?.row);
                    setShowEditRowModal(true);
                  }}
                />
              ) : null}
              <SoftDialog
                title={"Edit Row"}
                open={showEditRowModal}
                handleClose={(_) => {
                  setShowEditRowModal(false);
                  setFormData({
                    userId: "",
                    savingAccountId: "",
                    cardId: "",
                    clientId: "",
                    savingAccountNumber: "",
                  });
                }}
              >
                <EditRowComponent
                  setShowEditRowModal={setShowEditRowModal}
                  formData={formData}
                  setFormData={setFormData}
                  setUpdateRows={setUpdateRows}
                />
              </SoftDialog>
            </Box>
          </div>
        </>
      )}
    </div>
  );
}
