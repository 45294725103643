import React, { useEffect, useState } from "react";
import { Loader } from "../../components";
import classNames from "classnames";
import {
  getCouponsList,
  getEventsList,
  getSponsorsAPI,
  UpdateBoostedSponsorApproveAPI,
  UpdateBoostedSponsorRejectAPI,
  updateCouponGlobalRank,
  UpdateCouponStatus,
  UpdateEventStatus,
} from "../../api/api";
import UsersIcon from "../../assets/svgs/user_admaster.svg";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomButton from "../../components/atoms/Button/Button";
import AcceptRejectModel from "../admasterUsers/Accept_RejectModel";
import dayjs from "dayjs";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";

const CouponsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newParams = new URLSearchParams(searchParams);
  let stateActiveTab = newParams.get("type")
    ? parseInt(newParams.get("type"))
    : 2 || 2;
  let queryFilter = newParams.get("status") || "";
  let queryStatusPending = newParams.get("filter") || "";
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(stateActiveTab);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");

  const [showApproveModel, setShowApproveModel] = useState(false);
  const [showRejectionModel, setShowRejectionModel] = useState(false);
  const [selectedDataValue, setSelectedDataValue] = useState({});
  const [update, setUpdate] = useState(0);
  const [rejectMessage, setRejectMessage] = useState("");

  const filterModel = queryFilter
    ? {
        items: [
          {
            columnField: "status",
            operatorValue: "equals",
            value: queryFilter,
          },
        ],
      }
    : { items: [] };

  const columns = [
    ...[
      {
        field: "status",
        headerName: "Status",
        width: 100,
        renderCell: (params) => {
          const isExpired = params.row.status === "expired";
          const statusColor = isExpired ? "bg-red-500" : "bg-green-500";
          const statusText = isExpired ? "Expired" : "Active";
          return (
            <div className="flex items-center gap-2">
              <div
                className={`w-3 h-3 rounded-full ${statusColor} ${
                  isExpired ? "animate-pulse duration-500" : "animate-pulse"
                }`}
              ></div>
              <span className="select-none">{statusText}</span>
            </div>
          );
        },
      },
    ],
    ...(activeTab === 0
      ? [
          //active coupons
          {
            field: "poster",
            headerName: "Poster",
            width: 80,
            renderCell: (params) => (
              <img
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.poster}`}
                alt="Poster"
                className="w-10 h-10 rounded-full"
              />
            ),
          },
          {
            field: "instituteName",
            headerName: "Company Name",
            width: 150,
            renderCell: (params) => {
              // console.log(params);
              return (
                <div
                  onClick={(_) => {
                    if (!params.row?.user?.id)
                      return toast("User is not exist or deleted");
                    navigate(
                      `/estu-ads/discount-coupons/${params.row?.user?.id}`,
                      {
                        state: {
                          from: activeTab === 1 ? "event" : "",
                          lastPage: "/coupons-management",
                        },
                      }
                    );
                  }}
                  className={
                    "flex flex-row gap-2 align-center justify-center p-2 items-center"
                  }
                >
                  {params.row?.user?.profilePhoto ? (
                    <div className="flex items-center justify-center shrink-0">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.user?.profilePhoto}`}
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <img src={UsersIcon} className="w-10 h-10 rounded-full" />
                    </div>
                  )}
                  <div>
                    <p className="w-28 text-ellipsis whitespace-nowrap overflow-hidden">
                      {params.row?.user?.instituteName || "-----"}
                    </p>
                  </div>
                </div>
              );
            },
            // Custom filter function to enable filtering on instituteName
            renderCell: (params) => params.row?.user?.instituteName || "",
          },
          {
            field: "headline",
            headerName: "Headline",
            width: 200,
          },
          {
            field: "caption",
            headerName: "Caption",
            width: 200,
            renderCell: (params) => params.row?.caption || "---",
            // hide: activeTab === 1, // Hide for events tab
          },
        ]
      : activeTab === 1
      ? [
          //events
          {
            field: "poster",
            headerName: "Poster",
            width: 80,
            renderCell: (params) => (
              <img
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.poster}`}
                alt="Poster"
                className="w-10 h-10 rounded-full"
              />
            ),
          },
          {
            field: "instituteName",
            headerName: "Company Name",
            width: 150,
            renderCell: (params) => {
              // console.log(params);
              return (
                <div
                  onClick={(_) => {
                    if (!params.row?.user?.id)
                      return toast("User is not exist or deleted");
                    navigate(
                      `/estu-ads/discount-coupons/${params.row?.user?.id}`,
                      {
                        state: {
                          from: activeTab === 1 ? "event" : "",
                          lastPage: "/coupons-management",
                        },
                      }
                    );
                  }}
                  className={
                    "flex flex-row gap-2 align-center justify-center p-2 items-center"
                  }
                >
                  {params.row?.user?.profilePhoto ? (
                    <div className="flex items-center justify-center shrink-0">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.user?.profilePhoto}`}
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <img src={UsersIcon} className="w-10 h-10 rounded-full" />
                    </div>
                  )}
                  <div>
                    <p className="w-28 text-ellipsis whitespace-nowrap overflow-hidden">
                      {params.row?.user?.instituteName || "-----"}
                    </p>
                  </div>
                </div>
              );
            },
            // Custom filter function to enable filtering on instituteName
            renderCell: (params) => params.row?.user?.instituteName || "",
          },
          {
            field: "headline",
            headerName: "Headline",
            width: 150,
          },
          {
            field: "ticketTitle",
            headerName: "Ticket Title",
            width: 200,
            renderCell: (params) => params.row?.ticketTitle || "---",
            hide: activeTab !== 1, // Hide for events tab
          },
          {
            field: "when",
            headerName: "Start Date (EST)",
            width: 130,
            // renderCell: (params) => params.row?.when || "---",
            renderCell: (params) =>
              dayjs(params?.row?.when)
                ?.tz("America/New_York")
                .format("YYYY-MM-DD") || "---",

            // hide: activeTab !== 1, // Hide for events tab
          },
          {
            field: "tillDate",
            headerName: "Till Date (EST)",
            width: 130,
            // renderCell: (params) => params.row?.tillDate || "---",
            renderCell: (params) =>
              dayjs(params?.row?.tillDate)
                ?.tz("America/New_York")
                .format("YYYY-MM-DD") || "---",
          },
          {
            field: "time",
            headerName: "Start Time",
            width: 100,
            renderCell: (params) => params.row?.time || "---",
            // hide: activeTab !== 1, // Hide for events tab
          },
          {
            field: "till",
            headerName: "End Time",
            width: 100,
            renderCell: (params) => params.row?.till || "---",
            // hide: activeTab !== 1, // Hide for events tab
          },
        ]
      : activeTab === 2
      ? [
          //all coupons
          {
            field: "poster",
            headerName: "Poster",
            width: 80,
            renderCell: (params) => (
              <img
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.poster}`}
                alt="Poster"
                className="w-10 h-10 rounded-full"
              />
            ),
          },
          {
            field: "instituteName",
            headerName: "Company Name",
            width: 150,
            renderCell: (params) => {
              // console.log(params);
              return (
                <div
                  onClick={(_) => {
                    if (!params.row?.user?.id)
                      return toast("User is not exist or deleted");
                    navigate(
                      `/estu-ads/discount-coupons/${params.row?.user?.id}`,
                      {
                        state: {
                          from: activeTab === 1 ? "event" : "",
                          lastPage: "/coupons-management",
                        },
                      }
                    );
                  }}
                  className={
                    "flex flex-row gap-2 align-center justify-center p-2 items-center"
                  }
                >
                  {params.row?.user?.profilePhoto ? (
                    <div className="flex items-center justify-center shrink-0">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.user?.profilePhoto}`}
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <img src={UsersIcon} className="w-10 h-10 rounded-full" />
                    </div>
                  )}
                  <div>
                    <p className="w-28 text-ellipsis whitespace-nowrap overflow-hidden">
                      {params.row?.user?.instituteName || "-----"}
                    </p>
                  </div>
                </div>
              );
            },
            // Custom filter function to enable filtering on instituteName
            renderCell: (params) => params.row?.user?.instituteName || "",
          },
          {
            field: "headline",
            headerName: "Headline",
            width: 200,
          },
          {
            field: "caption",
            headerName: "Caption",
            width: 200,
            renderCell: (params) => params.row?.caption || "---",
            // hide: activeTab === 1, // Hide for events tab
          },
        ]
      : activeTab === 3
      ? [
          //boosted events
          {
            field: "eventPoster",
            headerName: "Poster",
            width: 100,
            renderCell: (params) => (
              <img
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.eventPoster}`}
                alt="Poster"
                className="w-10 h-10 rounded-full"
              />
            ),
          },
          {
            field: "instituteName",
            headerName: "Company Name",
            width: 150,
            renderCell: (params) => {
              // console.log(params);
              return (
                <div
                  onClick={(_) => {
                    if (!params.row?.userId)
                      return toast("User is not exist or deleted");
                    navigate(
                      `/estu-ads/discount-coupons/${params.row?.userId}`,
                      {
                        state: {
                          from: activeTab === 1 ? "event" : "",
                          lastPage: "/coupons-management",
                        },
                      }
                    );
                  }}
                  className={
                    "flex flex-row gap-2 align-center justify-center p-2 items-center"
                  }
                >
                  {params.row?.profilePhoto ? (
                    <div className="flex items-center justify-center shrink-0">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.profilePhoto}`}
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <img src={UsersIcon} className="w-10 h-10 rounded-full" />
                    </div>
                  )}
                  <div>
                    <p className="w-28 text-ellipsis whitespace-nowrap overflow-hidden">
                      {params.row?.instituteName || "-----"}
                    </p>
                  </div>
                </div>
              );
            },
            // Custom filter function to enable filtering on instituteName
            renderCell: (params) => params.row?.instituteName || "",
          },
          {
            field: "eventName",
            headerName: "Event",
            width: 150,
            renderCell: (params) => params.row?.eventName || "---",
            // hide: activeTab !== 1, // Hide for events tab
          },
          {
            field: "start",
            headerName: "Start Date (EST)",
            width: 150,
            // renderCell: (params) => params.row?.when || "---",
            renderCell: (params) =>
              dayjs(params?.row?.start)
                ?.tz("America/New_York")
                .format("YYYY-MM-DD") || "---",

            // hide: activeTab !== 1, // Hide for events tab
          },
          {
            field: "end",
            headerName: "Till Date (EST)",
            width: 150,
            // renderCell: (params) => params.row?.tillDate || "---",
            renderCell: (params) =>
              dayjs(params?.row?.end)
                ?.tz("America/New_York")
                .format("YYYY-MM-DD") || "---",

            // hide: activeTab !== 1, // Hide for events tab
            // hide: activeTab !== 1, // Hide for events tab
          },
          {
            field: "cost",
            headerName: "Budget(USD)",
            type: "number",
            width: 100,
          },

          {
            field: "university",
            headerName: "University/College",
            renderCell: (params) => {
              const university = params?.row?.targetMetaData?.university;
              return (
                <div className="text-center">
                  {university ? university : "---"}
                </div>
              );
            },
            width: 160,
            editable: false,
          },
          {
            field: "state",
            headerName: "State",
            renderCell: (params) => {
              const state = params?.row?.targetMetaData?.state;
              return <div className="text-center">{state ? state : "---"}</div>;
            },
            width: 110,
            editable: false,
          },
          {
            field: "city",
            headerName: "City",
            renderCell: (params) => {
              const city = params?.row?.targetMetaData?.city;
              return <div className="text-center">{city ? city : "---"}</div>;
            },
            width: 110,
            editable: false,
          },
          {
            field: "approval",
            headerName: "Action",
            width: 180,
            // hide: statusFilter !== "PENDING",
            renderCell: (param) => {
              const data = param.row;

              const row = {
                ...data,
              };

              return (
                <div className={``}>
                  {row?.approval ? (
                    row?.approval?.toLowerCase() === "pending" ? (
                      <div className={`flex flex-row gap-2 select-none`}>
                        {/* {" "} */}
                        <CustomButton
                          onClick={(_) => {
                            setShowApproveModel(true);
                            setSelectedDataValue(row);
                          }}
                        >
                          Approve
                        </CustomButton>
                        <CustomButton
                          onClick={(_) => {
                            setShowRejectionModel(true);
                            setSelectedDataValue(row);
                          }}
                          color="red"
                          variant={"contained"}
                        >
                          Reject
                        </CustomButton>
                      </div>
                    ) : (
                      <div>{row?.approval?.toUpperCase()}</div>
                    )
                  ) : null}
                </div>
              );
            },
          },
        ]
      : activeTab === 4
      ? [
          //boosted coupons
          {
            field: "dealPoster",
            headerName: "Poster",
            width: 80,
            renderCell: (params) => (
              <img
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.dealPoster}`}
                alt="Poster"
                className="w-10 h-10 rounded-full"
              />
            ),
          },
          {
            field: "instituteName",
            headerName: "Company Name",
            width: 150,
            renderCell: (params) => {
              // console.log(params);
              return (
                <div
                  onClick={(_) => {
                    if (!params.row?.userId)
                      return toast("User is not exist or deleted");
                    navigate(
                      `/estu-ads/discount-coupons/${params.row?.userId}`,
                      {
                        state: {
                          from: activeTab === 1 ? "event" : "",
                          lastPage: "/coupons-management",
                        },
                      }
                    );
                  }}
                  className={
                    "flex flex-row gap-2 align-center justify-center p-2 items-center"
                  }
                >
                  {params.row?.profilePhoto ? (
                    <div className="flex items-center justify-center shrink-0">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.profilePhoto}`}
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <img src={UsersIcon} className="w-10 h-10 rounded-full" />
                    </div>
                  )}
                  <div>
                    <p className="w-28 text-ellipsis whitespace-nowrap overflow-hidden">
                      {params.row?.instituteName || "-----"}
                    </p>
                  </div>
                </div>
              );
            },
            // Custom filter function to enable filtering on instituteName
            renderCell: (params) => params.row?.instituteName || "",
          },
          {
            field: "dealName",
            headerName: "Deal",
            width: 150,
            renderCell: (params) => params.row?.dealName || "---",
            // hide: activeTab !== 1, // Hide for events tab
          },
          {
            field: "start",
            headerName: "Start Date (EST)",
            width: 150,
            renderCell: (params) =>
              dayjs(params?.row?.start)
                ?.tz("America/New_York")
                .format("YYYY-MM-DD") || "---",
          },
          {
            field: "end",
            headerName: "Till Date (EST)",
            width: 150,
            renderCell: (params) =>
              dayjs(params?.row?.end)
                ?.tz("America/New_York")
                .format("YYYY-MM-DD") || "---",
          },
          {
            field: "cost",
            headerName: "Budget(USD)",
            type: "number",
            width: 110,
          },

          {
            field: "university",
            headerName: "University/College",
            renderCell: (params) => {
              const university = params?.row?.targetMetaData?.university;
              return (
                <div className="text-center">
                  {university ? university : "---"}
                </div>
              );
            },
            width: 160,
            editable: false,
          },
          {
            field: "state",
            headerName: "State",
            renderCell: (params) => {
              const state = params?.row?.targetMetaData?.state;
              return <div className="text-center">{state ? state : "---"}</div>;
            },
            width: 110,
            editable: false,
          },
          {
            field: "city",
            headerName: "City",
            renderCell: (params) => {
              const city = params?.row?.targetMetaData?.city;
              return <div className="text-center">{city ? city : "---"}</div>;
            },
            width: 110,
            editable: false,
          },
          {
            field: "approval",
            headerName: "Action",
            width: 180,
            // hide: statusFilter !== "PENDING",
            renderCell: (param) => {
              const data = param.row;

              const row = {
                ...data,
              };

              return (
                <div className={``}>
                  {row?.approval ? (
                    row?.approval?.toLowerCase() === "pending" ? (
                      <div className={`flex flex-row gap-2 select-none`}>
                        {" "}
                        <CustomButton
                          onClick={(_) => {
                            setShowApproveModel(true);
                            setSelectedDataValue(row);
                          }}
                        >
                          Approve
                        </CustomButton>
                        <CustomButton
                          onClick={(_) => {
                            setShowRejectionModel(true);
                            setSelectedDataValue(row);
                          }}
                          color="red"
                          variant={"contained"}
                        >
                          Reject
                        </CustomButton>
                      </div>
                    ) : (
                      <div>{row?.approval?.toUpperCase()}</div>
                    )
                  ) : null}
                </div>
              );
            },
          },
        ]
      : [
          {
            field: "isStatus",
            headerName: "Action",
            width: 200,
            hide: statusFilter !== "PENDING",
            renderCell: (param) => {
              const data = param.row;

              const row = {
                ...data,
              };

              return (
                <div className={``}>
                  {row?.isStatus ? (
                    row?.isStatus?.toLowerCase() === "pending" ? (
                      <div className={`flex flex-row gap-2 select-none`}>
                        {" "}
                        <CustomButton
                          onClick={(_) => {
                            setShowApproveModel(true);
                            setSelectedDataValue(row);
                          }}
                        >
                          Approve
                        </CustomButton>
                        <CustomButton
                          onClick={(_) => {
                            setShowRejectionModel(true);
                            setSelectedDataValue(row);
                          }}
                          color="red"
                          variant={"contained"}
                        >
                          Reject
                        </CustomButton>
                      </div>
                    ) : (
                      row.isStatus
                    )
                  ) : null}
                </div>
              );
            },
          },
        ]),
  ];

  const handleTabChange = (type) => {
    setActiveTab(type);
    setSearchParams({ type });
  };

  const handleCellEditCommit = async ({ id, field, row, ...props }) => {
    // Validate input to allow only numbers
    if (!/^\d*$/.test(props?.value)) {
      toast.error("Please enter a valid number");

      return; // If not a number, do not proceed
    }

    try {
      //  update globalRank
      if (activeTab === 0 || activeTab === 2) {
        // Update for active Coupons
        await updateCouponGlobalRank({
          couponId: id,
          rank: parseInt(props?.value, 10),
        });
      } else {
        // Update for Events
        toast("Event Global Rank is not currently active");
        // await UpdateEventStatus(id, true, { globalRank: parseInt(value, 10) });
      }

      // If API call is successful, update state to trigger re-render
      setUpdate((prev) => prev + 1);
      toast.success("Global Rank updated successfully");
    } catch (error) {
      console.error("Error updating global rank:", error);
      toast.error("Failed to update Global Rank");
    }
  };

  const approveData = async () => {
    if (activeTab === 0 || activeTab === 2) {
      //Discounts Active Coupons
      try {
        const res = await UpdateCouponStatus(selectedDataValue.id, true);
        setShowApproveModel(false);
        // console.log(res);
        toast.success(res?.message);
        setUpdate((prev) => prev + 1);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");
      }
    } else if (activeTab === 1) {
      try {
        const res = await UpdateEventStatus(selectedDataValue.id, true);
        setShowApproveModel(false);
        setUpdate((prev) => prev + 1);
        toast.success(res?.message);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");
      }
    } else if (activeTab === 3 || activeTab === 4) {
      try {
        const res = await UpdateBoostedSponsorApproveAPI(
          selectedDataValue.id,
          true
        );
        setShowApproveModel(false);
        setUpdate((prev) => prev + 1);
        toast.success(res?.message);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");
      }
    }
  };

  const rejectData = async () => {
    if (!rejectMessage) {
      toast.error("Please enter a reason for rejection");
      return;
    }

    if (activeTab === 0 || activeTab === 2) {
      try {
        const res = await UpdateCouponStatus(
          selectedDataValue.id,
          false,
          rejectMessage
        );
        setShowRejectionModel(false);
        setUpdate((prev) => prev + 1);
        toast.success(res?.message);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");
      } finally {
        setRejectMessage("");
      }
    } else if (activeTab === 1) {
      try {
        const res = await UpdateEventStatus(
          selectedDataValue.id,
          false,
          rejectMessage
        );
        setShowRejectionModel(false);
        setUpdate((prev) => prev + 1);
        toast.success(res?.message);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");
      } finally {
        setRejectMessage("");
      }
    } else if (activeTab === 3 || activeTab === 4) {
      //boosted 3 event 4 deals
      try {
        const res = await UpdateBoostedSponsorRejectAPI(
          selectedDataValue.id,
          false,
          rejectMessage
        );
        setShowRejectionModel(false);
        setUpdate((prev) => prev + 1);
        toast.success(res?.message);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");
      } finally {
        setRejectMessage("");
      }
    }
  };

  const getAPI = async (tab) => {
    setPageLoading(true);
    if (tab === 0 || tab === 2) {
      //coupons
      let params = {};

      if (tab === 0) {
        // Case for tab === 0 //active only
        params = { expired: "none", random: true }; //Note:- Added random true to remove globalRank Concept
      } else if (tab === 2) {
        params = { random: true };
      }
      try {
        const response = await getCouponsList(params);
        if (response?.status) {
          setData(response.data || []);
          setFilteredData(response.data || []);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setPageLoading(false);
      }
    } else if (tab === 1) {
      //events
      try {
        const response = await getEventsList({});
        // console.log(response);
        if (response?.status) {
          setData(response.data || []);
          setFilteredData(response.data || []);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setPageLoading(false);
      }
    } else {
      try {
        const response = await getSponsorsAPI({
          type: activeTab === 3 ? "event" : "deal",
        });
        // console.log(response);
        if (response?.status) {
          setData(response.data || []);
          setFilteredData(response.data || []);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setPageLoading(false);
      }
    }
  };

  useEffect(() => {
    if (queryStatusPending) {
      setStatusFilter("PENDING");
    } else {
      setStatusFilter("");
    }
    setPage(1);
  }, [location]);

  useEffect(() => {
    // Set the initial active tab based on navigation state
    setPage(1);

    getAPI(activeTab);

    if (queryStatusPending) {
      setStatusFilter("PENDING");
    } else {
      setStatusFilter("");
    }
  }, [activeTab, setActiveTab]);

  useEffect(() => {
    if (update) getAPI(activeTab);
  }, [update]);

  useEffect(() => {
    if ([0, 1, 2].includes(activeTab)) {
      if (statusFilter) {
        setFilteredData(Data.filter((item) => item.isStatus === statusFilter));
      } else {
        setFilteredData(Data);
      }
    } else {
      if (statusFilter) {
        setFilteredData(Data.filter((item) => item.approval === statusFilter));
      } else {
        setFilteredData(Data);
      }
    }
  }, [statusFilter, Data]);

  return (
    <div className="flex gap-4 md:flex-col h-full max-h-screen">
      <h2 className="px-4 text-[#344767] font-roboto font-bold text-2xl leading-[26px]">
        Coupons And Events
      </h2>{" "}
      <div className="flex flex-col bg-white rounded-[10px] gap-5 shadow-[10px_10px_10px_lightgrey] px-5 py-2.5 m-2.5 h-full">
        {/* start tab navigation */}
        <div className={"flex w-full items-center justify-between"}>
          <div
            className={`flex flex-row gap-3`}
            style={{ alignItems: "center" }}
          >
            <div
              onClick={() => handleTabChange(0)}
              className={classNames(
                "cursor-pointer w-[148px] h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#67748e] font-montserrat text-xs font-semibold leading-4 [letter-spacing:_0.3428399860858917px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
                {
                  "font-montserrat border-b border-b-[#67748e] text-xs transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.3428399860858917px;] text-center":
                    activeTab === 0,
                }
              )}
            >
              Active Coupons
            </div>
            <div
              onClick={(_) => handleTabChange(2)}
              className={classNames(
                "cursor-pointer w-[148px] h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#67748e] font-montserrat text-xs font-semibold leading-4 [letter-spacing:_0.3428399860858917px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
                {
                  "font-montserrat border-b border-b-[#67748e] text-xs transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.3428399860858917px;] text-center":
                    activeTab === 2,
                }
              )}
            >
              All Coupons
            </div>
            <div
              onClick={(_) => handleTabChange(1)}
              className={classNames(
                "cursor-pointer w-[148px] h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#67748e] font-montserrat text-xs font-semibold leading-4 [letter-spacing:_0.3428399860858917px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
                {
                  "font-montserrat border-b border-b-[#67748e] text-xs transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.3428399860858917px;] text-center":
                    activeTab === 1,
                }
              )}
            >
              Events
            </div>
            <div
              onClick={(_) => handleTabChange(3)}
              className={classNames(
                "cursor-pointer w-[148px] h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#67748e] font-montserrat text-xs font-semibold leading-4 [letter-spacing:_0.3428399860858917px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
                {
                  "font-montserrat border-b border-b-[#67748e] text-xs transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.3428399860858917px;] text-center":
                    activeTab === 3,
                }
              )}
            >
              Boosted&nbsp;Events
            </div>
            <div
              onClick={(_) => handleTabChange(4)}
              className={classNames(
                "cursor-pointer w-[148px] h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#67748e] font-montserrat text-xs font-semibold leading-4 [letter-spacing:_0.3428399860858917px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
                {
                  "font-montserrat border-b border-b-[#67748e] whitespace-nowrap text-xs transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.3428399860858917px;] text-center":
                    activeTab === 4,
                }
              )}
            >
              Boosted&nbsp;Coupons
            </div>
          </div>

          <div className="flex flex-row items-center gap-2 border-b pb-2">
            <label
              htmlFor="statusFilter"
              className="text-sm font-medium text-gray-700"
            >
              Filter by Status:
            </label>
            <select
              id="statusFilter"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="border border-gray-300 rounded-md px-2 py-1 text-sm cursor-pointer"
            >
              <option value="">All</option>
              <option value="PENDING">Pending</option>
              <option value="APPROVED">Approved</option>
              <option value="REJECTED">Rejected</option>
            </select>
          </div>
        </div>
        {/* end tab navigation */}
        <div className="h-full">
          {pageLoading ? (
            <Loader />
          ) : filteredData?.length > 0 ? (
            <>
              <CDataGrid
                rows={filteredData}
                columns={columns.map((column, idx) => ({
                  ...column,
                  indexId: idx + 1,
                  editable: column.field === "globalRank" && activeTab !== 1,
                }))}
                pagination
                onCellEditCommit={handleCellEditCommit}
                //   selectionModel={selectionModel}
                filterModel={filterModel}
                loading={pageLoading}
              />
            </>
          ) : (
            <div className="h-full flex flex-col items-center justify-center select-none">
              <p className="text-center hover:scale-105 transition-transform font-montserrat cursor-pointer">
                No Data Available!
              </p>
            </div>
          )}

          {/* start modals */}
          <AcceptRejectModel
            handleClose={(_) => setShowApproveModel(false)}
            open={showApproveModel}
            onClick={approveData}
            title={`Approve ${activeTab === 0 ? "Coupon" : "Event"}`}
          />
          <AcceptRejectModel
            showReject={true}
            title={`Reject ${activeTab == 0 ? "Coupon" : "Event"}`}
            handleClose={(_) => {
              setShowRejectionModel(false);
              setRejectMessage("");
            }}
            open={showRejectionModel}
            onClick={rejectData}
            rejectMessage={rejectMessage}
            setRejectMessage={setRejectMessage}
            reasonAdd={true}
          />
          {/* end modals */}
        </div>
      </div>
    </div>
  );
};

export default CouponsPage;
