import React, { useEffect, useState, useRef } from "react";
import style from "./style.module.css";
import { FormConfig } from "./formConfig";
import Image1121 from "../../assets/imgs/cpimg1.png";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "./../../components/atoms/Loader";
import SoftDialog from "../../components/molecules/dialogs";
import Button from "./../../components/atoms/Button";
import { UserIcon } from "../../assets/svgs/svgs";
import { CreateAllowedUser, GetAllAllowedUsers } from "../../api/api";
import GenericFormComponent from "../../components/Form/Form";
import dayjs from "dayjs";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";
import { Typography } from "@mui/material";

export default function AllowedUsers() {
  const [Data, setData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const columns = [
    { field: "id", headerName: "Id", width: 50 },
    { field: "email", headerName: "email", width: 150 },
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "createdAt",
      headerName: "Created At (EST)",
      width: 150,

      //   const options = {
      //     hour12: false,
      //     year: "numeric",
      //     month: "2-digit",
      //     day: "2-digit",
      //     hour: "2-digit",
      //     minute: "2-digit",
      //     second: "2-digit",
      //   };
      //   const formattedDateTime = new Date(params.row.createdAt)
      //     .toLocaleString("en-US", options)
      //     .replace(
      //       /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/,
      //       "$1-$2-$3 $4:$5:$6"
      //     );

      //   return <p>{formattedDateTime}</p>;
      // },
      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
          {dayjs(params?.row?.createdAt)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD:HH:mm:ss")}
        </p>
      ),
    },
  ];

  const getAPI = async () => {
    setLoading(true);
    try {
      GetAllAllowedUsers().then((res) => setData(res));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAPI();
  }, [step]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.title}>Allowed Users</div>
        <div>
          <Button
            onClick={(_) => setShowForm(true)}
            bgColor="#2CCCD3"
            color="white"
            borderRadius="10px"
            icon={<UserIcon />}
            text={"Create Allowed Users"}
          />
        </div>
      </div>
      <SoftDialog
        title={step === 0 ? "Create user" : ""}
        open={showForm}
        handleClose={(_) => {
          setShowForm(false);
          setStep(0);
        }}
      >
        {step === 0 ? (
          <FormComponent
            setStep={setStep}
            step={step}
            formData={formData}
            setFormData={setFormData}
          />
        ) : (
          <SuccessFulllStep />
        )}
      </SoftDialog>

      <div className={`flex flex-column gap-2 align-center h-auto`}>
        <Box sx={{ height: "83vh", width: "100%" }}>
          {Data?.length === 0 && loading ? (
            <Loader />
          ) : (
            <CDataGrid
              rows={Data}
              columns={columns}
              pagination
              loading={loading}
            />
          )}
        </Box>
      </div>
    </div>
  );
}

const FormComponent = ({ formData, setFormData, step, setStep }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    CreateAllowedUser(formData).then((res) => {
      setFormData({});
      setStep(1);
    });
  };

  return (
    <GenericFormComponent
      formConfig={FormConfig}
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
    />
  );
};

const SuccessFulllStep = () => {
  return (
    <div
      className={`flex flex-col p-5 gap-5 w-100`}
      style={{ alignItems: "center" }}
    >
      <div className={"w-[70] h-[70] mx-5"}>
        <img src={Image1121} style={{ width: 70, height: 70 }} />
      </div>
      <div className={style.successtext}>User created successfuly !!</div>
    </div>
  );
};
