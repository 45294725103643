import { Add, Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import dummyNewsLogo from "../../assets/imgs/dummy_news_logo.png";
import SoftCustomModel from "../../shared/SoftCustomModel/SoftCustomModel";
import { Loader } from "../../components";
import {
  addNewMediaAPI,
  deleteSingleMediaAPI,
  editNewMediaAPI,
  getMediaListAPI,
} from "../../api/api";
import { toast } from "react-toastify";
import { ImageUpload } from "../adsmaster/discountCoupons/imageUpload";

import AddEditMedia from "./AddEditMedia";
const Media = () => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [loading, setLoading] = useState([]);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editData, setEditData] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [errors, setErrors] = useState({});

  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    outlet: "",
    headline: "",
    newsLink: "",
    banner: "",
    publish_date: "",
  });
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setDeleteModalOpen(true);
  };

  const resetFormData = () => {
    setFormData({
      outlet: "",
      headline: "",
      newsLink: "",
      banner: "",
      publish_date: "",
    });
    setErrors({});
    setFormValid(false);
  };
  const setFieldError = (field, message) => {
    setErrors((prevErrors) => {
      if (message) {
        return { ...prevErrors, [field]: message };
      } else {
        const { [field]: _, ...rest } = prevErrors;
        return rest;
      }
    });
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteSingleMediaAPI({ id: itemToDelete });

      toast.success(
        response?.data?.message ||
          `Data with id ${itemToDelete} deleted successfully`
      );

      setData((prevData) =>
        prevData.filter((_, index) => _?.id !== itemToDelete)
      );
      setDeleteModalOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.error || error?.message || "Error deleting data");
    }
  };
  const handleModalClose = () => {
    setAddModalOpen(false);
    resetFormData();
    setEditData(false);
  };
  const handleEditClick = (rowData) => {
    setFormValid(true);
    setFormData(rowData);
    setEditData(true);
    setAddModalOpen(true);
  };
  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const getAPI = async () => {
    setLoading(true);
    try {
      const response = await getMediaListAPI();

      if (response?.status) {
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.error || error?.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };
  const handleBannerUpload = async (event) => {
    setBannerLoading(true);
    await ImageUpload(event, setFormData, "banner");
    if (event?.target?.files?.length > 0) {
      setFieldError("banner", "");
    } else {
      setFieldError("banner", "Please upload an image.");
    }
    setBannerLoading(false);
  };
  const handleChange = (e) => {
    const { name, value } = e?.target;
    console.log(value);

    if (name === "headline" || name === "outlet") {
      if (value.length <= 40) {
        setFormData((prev) => ({ ...prev, [name]: value }));
        setFieldError(name, "");
      } else {
        setFieldError(name, `${name} should not exceed 40 characters`);
      }
    } else if (name === "newsLink") {
      setFormData((prev) => ({ ...prev, [name]: value }));
      setFieldError(name, "");
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
      setFieldError(name, "");
    }
  };
  const checkUrl = (value) => {
    const urlRegex =
      /^(https?:\/\/|www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[^\s]*)?$/;

    if (urlRegex.test(value)) {
      setFieldError("newsLink", "");
      return true;
    } else {
      setFieldError(
        "newsLink",
        "Invalid URL. Make sure it starts with 'http://', 'https://', or 'www.  and includes a valid domain.'"
      );
    }
    return false;
  };
  const handleOnBlurDate = (e) => {
    const { name, value } = e?.target;

    if (name === "publish_date") {
      // Regex for validating date in YYYY-MM-DD format
      const datePattern = /^\d{4}-\d{2}-\d{2}$/;
      const minDate = new Date("1999-01-01");
      const maxDate = new Date("2050-12-31");

      if (value && datePattern.test(value)) {
        const selectedDate = new Date(value);

        if (selectedDate >= minDate && selectedDate <= maxDate) {
          setFormData((prev) => ({ ...prev, [name]: value }));
        } else {
          setFieldError(name, "Publish date must be between 1999 and 2050");
          setFormValid(false);
        }
      } else if (value && !datePattern.test(value)) {
        setFieldError(
          name,
          "Invalid date format. Please use the format YYYY-MM-DD."
        );
        setFormValid(false);
      } else {
        setFieldError(name, "Publish date is required");
        setFormValid(false);
      }
    }
  };
  const handleOnBlur = (e) => {
    const { name, value } = e?.target;
    console.log("bharat", name, value);
    if (name === "newsLink") {
      if (value) {
        checkUrl(value);
      } else {
        setFieldError(name, `website url is required`);
      }
      return;
    }
    if (!value) {
      setFieldError(name, `${name.replace(/_/g, " ")} is required`);
    } else {
      setFieldError(name, "");
    }
  };
  const handleSubmit = async () => {
    let isValid = true;

    if (!formData.outlet) {
      setFieldError("outlet", "Media outlet is required");
      isValid = false;
    }
    if (!formData.headline) {
      setFieldError("headline", "Headline is required");
      isValid = false;
    }
    if (!formData.newsLink || !checkUrl(formData.newsLink)) {
      // setFieldError("newsLink", "Website URL is required");
      isValid = false;
    }
    if (!formData.publish_date) {
      setFieldError("publish_date", "Publish date is required");
      isValid = false;
    }
    if (!formData.banner) {
      setFieldError("banner", "Logo is required");
      isValid = false;
    }

    if (!isValid) {
      toast.error("Please fix the errors before submitting.");
      return;
    }

    if (checkUrl(formData?.newsLink)) {
      if (editData) {
        //edit api
        setAddLoading(true);
        try {
          const response = await editNewMediaAPI(formData);
          if (response?.status) {
            toast.success(
              response?.data?.message || "Data Updated successfully"
            );
            setTimeout(() => {
              handleModalClose();
            }, [500]);
            getAPI();
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.error || error?.message || "Error adding data");
        } finally {
          setAddLoading(false);
        }
      } else {
        //add api
        setAddLoading(true);
        try {
          const response = await addNewMediaAPI(formData);
          if (response?.status) {
            toast.success(response?.data?.message || "Data added successfully");
            setTimeout(() => {
              handleModalClose();
            }, [500]);
            getAPI();
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.error || error?.message || "Error adding data");
        } finally {
          setAddLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    getAPI();
  }, []);

  useEffect(() => {
    const isDateValid =
      formData?.publish_date &&
      new Date(formData.publish_date) >= new Date("1999-01-01") &&
      new Date(formData.publish_date) <= new Date("2050-12-31");

    if (
      formData?.outlet &&
      formData?.headline &&
      formData?.newsLink &&
      formData?.banner &&
      isDateValid
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData]);

  return (
    <div className="px-4 py-[12px] flex flex-col gap-6 h-full justify-start bg-[#F8F9FA]">
      <div className="flex items-center justify-between w-full">
        <h2 className="text-[#344767] font-roboto font-bold text-2xl leading-[26px]">
          Our Media
        </h2>
        <button
          onClick={handleAddClick}
          className="px-7 py-3 hover:shadow-sm active:scale-95 duration-300 rounded-lg bg-[#2cccd3] text-white text-sm font-bold font-montserrat flex justify-center items-center text-center"
        >
          <Add />
          &nbsp;Add a news
        </button>
      </div>
      <hr />
      <div className="flex flex-col flex-1 justify-center items-center">
        {loading ? (
          <Loader />
        ) : data?.length === 0 ? (
          <p>No Media</p>
        ) : (
          <MediaTable
            data={data}
            columns={[
              { name: "Media Outlet" },
              { name: "Headline" },
              { name: "Logo" },
              { name: "News link" },
              { name: "Publish Date" },
              { name: "Action" },
            ]}
            onDelete={handleDeleteClick}
            onEdit={handleEditClick}
          />
        )}
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
      <SoftCustomModel
        isVisible={isAddModalOpen}
        onClose={handleModalClose}
        title={editData ? "Edit News" : "Add News"}
      >
        <AddEditMedia
          formData={formData}
          handleChange={handleChange}
          addLoading={addLoading}
          handleSubmit={handleSubmit}
          handleOnBlurDate={handleOnBlurDate}
          handleBannerUpload={handleBannerUpload}
          buttonText={editData ? "Update Changes" : "Upload news"}
          validForm={formValid}
          errors={errors}
          handleOnBlur={handleOnBlur}
          bannerLoading={bannerLoading}
        />
      </SoftCustomModel>
    </div>
  );
};

const MediaTable = ({ data, columns, onDelete, onEdit }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleModalClose = () => {
    setSelectedImage(null);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <table className="w-full h-full rounded-lg">
      <thead>
        <tr className="grid grid-cols-6 border-b">
          {columns?.map((col, idx) => (
            <th
              key={idx}
              className="text-lg font-montserrat font-bold text-[#2C3030] text-left px-4 py-2.5"
            >
              {col?.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="w-full h-full relative rounded-lg py-4 flex justify-start flex-col gap-4 overflow-y-scroll">
        {data?.map((row, index) => {
          return (
            <tr
              key={row?.id}
              className="grid grid-cols-6 items-center justify-center rounded-lg hover:bg-black cursor-pointer hover:bg-opacity-10"
            >
              <td
                key={row?.outlet}
                title={row?.id}
                className="text-[#2C3030] px-4 py-2 text-sm font-montserrat font-bold text-ellipsis whitespace-nowrap overflow-x-hidden overscroll-x-none"
              >
                {row?.outlet ? row?.outlet : "---"}
              </td>
              <td
                key={row?.headline}
                title={row?.id}
                className="text-[#2C3030] px-4 py-2 text-sm font-montserrat font-bold text-ellipsis whitespace-nowrap overflow-x-hidden overscroll-x-none"
              >
                {row?.headline}
              </td>
              <td key={index} className="px-4 py-2">
                {row?.banner ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASEURL}${row?.banner}`}
                    className="w-full object-cover h-[26px] bg-white border-white border-2"
                    alt="logo"
                    onClick={() => handleImageClick(row?.banner)}
                  />
                ) : (
                  <img
                    src={dummyNewsLogo}
                    className="w-[166px] h-[26px]"
                    alt="logo"
                  />
                )}
              </td>
              <td
                onClick={() => {
                  const url =
                    row?.newsLink?.startsWith("http://") ||
                    row?.newsLink?.startsWith("https://")
                      ? row?.newsLink
                      : `https://${row?.newsLink}`;
                  window.open(url, "_blank");
                }}
                key={row?.newsLink}
                className="px-4 py-2 cursor-pointer hover:scale-105 duration-300 transition-all font-montserrat text-sm font-bold text-[#2CCCD3] underline text-ellipsis whitespace-nowrap overflow-x-hidden overscroll-x-none"
              >
                {row?.newsLink}
              </td>
              <td
                key={row?.publish_date}
                className="text-[#2C3030] px-4 py-2 text-sm font-montserrat font-bold text-ellipsis whitespace-nowrap overflow-x-hidden overscroll-x-none"
              >
                {row?.publish_date ? row?.publish_date : "---"}
              </td>
              <td key={row?.id}>
                <div className="flex flex-row gap-2.5 items-center justify-start">
                  <EditButton onClick={() => onEdit(row)} />
                  <DeleteButton onClick={() => onDelete(row?.id)} />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
      {/* Modal for Selected Image */}
      {selectedImage && (
        <div
          onClick={(e) => {
            handleModalClose();
          }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="relative bg-white rounded-lg"
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleModalClose();
              }}
              className="absolute top-2 right-2 text-gray-500 bg-white bg-opacity-80 rounded-full py-1.5 px-3 hover:text-gray-800"
            >
              ✕
            </button>
            <img
              src={`${process.env.REACT_APP_IMAGE_BASEURL}${selectedImage}`}
              alt="Full Banner Preview"
              className="max-w-full max-h-[40vh] min-h-[10vh] rounded-md"
            />
          </div>
        </div>
      )}
    </table>
  );
};

const EditButton = ({ onClick = () => {} }) => {
  return (
    <button
      className="text-white flex justify-center items-center text-sm gap-1 bg-[#2cccd3] px-5 py-3 rounded-lg"
      onClick={onClick}
    >
      <Edit fontSize="small" /> <p>Edit</p>
    </button>
  );
};
const DeleteButton = ({ onClick = () => {} }) => {
  return (
    <button
      className="text-white flex justify-center text-sm items-center gap-1 bg-[#F6285F] px-5 py-3 rounded-lg"
      onClick={onClick}
    >
      <Delete fontSize="small" /> <p>Delete</p>
    </button>
  );
};

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-[400px] text-center">
        <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
        <p className="text-gray-700 mb-6">
          Are you sure you want to delete this item?
        </p>
        <div className="flex justify-center gap-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded-lg"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg"
            onClick={onConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default Media;
