import { DataGrid } from "@mui/x-data-grid";
import classNames from "classnames";
import React, { useCallback, useState } from "react";

const CDataGrid = ({
  rows,
  columns,
  className = "bg-white",
  pagination = true,
  page = 0,
  pageSize = rows?.length > 100
    ? 100
    : rows?.length > 50
    ? 50
    : rows?.length > 20
    ? 20
    : 10,
  paginationMode = "client",
  loading,
  totalRecords,
  rowCount,
  handlePageChange = () => {},
  handlePageSizeChange = () => {},
  ...rest
}) => {
  console.log(pageSize, rows);
  const [cPageSize, setCPageSize] = useState(pageSize || 100);
  const onPageSizeChange = useCallback(
    (pageSize) => {
      setCPageSize(pageSize);
      handlePageSizeChange(pageSize);
    },
    [handlePageChange, handlePageSizeChange]
  );
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pagination={pagination}
      // page={page}
      pageSize={cPageSize}
      onPageChange={handlePageChange}
      onPageSizeChange={onPageSizeChange}
      loading={loading}
      className={classNames("bg-white", className)}
      rowsPerPageOptions={[10, 20, 25, 50, 100]}
      rowCount={rows?.length}
      {...rest}
    />
  );
};

export default CDataGrid;
