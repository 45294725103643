import classNames from "classnames";
import ChipCard from "./ChipCard";

const DashboardSection = ({ title, data, type }) => {
	return (
		<div
			className={classNames("flex flex-col mb-6", {
				"gap-0": !title,
				"gap-6": title,
			})}
		>
			{title && (
				<h5 className='text-[#344767] font-roboto font-medium text-2xl leading-[26px] select-none transition-transform hover:scale-105 cursor-pointer text-left w-fit'>
					{title}
				</h5>
			)}
			<div className='flex-1 md:grid md:grid-cols-3 grid grid-cols-2 gap-4'>
				{data?.map((item, idx) => (
					<ChipCard key={idx} data_obj={item} type={type} />
				))}
			</div>
		</div>
	);
};

export default DashboardSection;
