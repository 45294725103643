import React, { useEffect, useState } from "react";
import {
  createCampaignAPI,
  getCampaignCodeAPI,
  getCampaignListAPI,
  toggleActiveStatusCampaignAPI,
  toggleEstuFridayAPI,
  updateCampaignAPI,
} from "../../api/api";
import { DataGrid } from "@mui/x-data-grid";
import UsersIcon from "../../assets/svgs/user_admaster.svg";
import { ImageUpload } from "../adsmaster/discountCoupons/imageUpload";
import campaignOffer from "../../assets/svgs/campaignOffer.svg";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import bg_logo_estu from "../../assets/imgs/bg_logo_estu.png";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "../../utils/dayjs";
import { toast } from "react-toastify";
import ViewCampaignInsight from "../adsmaster/ViewCampaignInsight";
import classNames from "classnames";
import { CameraAltRounded } from "@mui/icons-material";
import SwitchToggle from "../../shared/Switch/SwitchToggle";
import { handleNonNegativeChange } from "../../utils/utils";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";

const CampaignManagement = () => {
  const [openModal, setOpenModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(true);
  const [cData, setCData] = useState([]);
  const [codes, setCodes] = useState([]);
  const [isDateValid, setIsDateValid] = useState(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [viewCampInsight, setViewCampInsight] = useState(false);
  const [isUpdateState, setIsUpdateState] = useState(false);

  const [formDataC, setFormDataC] = useState({
    name: "",
    description: "",
    banner: "",
    transactionCode: "",
    startDate: dayjs()?.startOf("day")?.format("YYYY-MM-DD"),
    endDate: dayjs()?.endOf("day")?.format("YYYY-MM-DD"),
    discountPercentage: "",
    maxDiscountAmount: "",
    totalBudget: "",
    headline: "",
  });

  const getAPI = async () => {
    setLoading(true);
    if (active == 0) {
      try {
        const res = await getCampaignListAPI();
        const codes = await getCampaignCodeAPI();
        if (res.status === "success") {
          setCData(res?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const getCodesAPI = async () => {
    setLoading(true);
    if (active == 0) {
      try {
        const res = await getCampaignCodeAPI();
        if (res.status === "success") {
          setCodes(res?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getAPI();
    getCodesAPI();
  }, [active]);

  const handlePreview = (data) => {
    setIsPreviewOpen(true);
    setSelectedCampaign(data);
  };
  const handleBannerUpload = async (event) => {
    await ImageUpload(event, setFormDataC, "banner");
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setViewMode(false);
    setFormDataC({
      name: "",
      description: "",
      banner: "",
      transactionCode: "",
      startDate: dayjs()?.startOf("day")?.format("YYYY-MM-DD"),
      endDate: dayjs()?.endOf("day")?.format("YYYY-MM-DD"),
      discountPercentage: "",
      maxDiscountAmount: "",
      totalBudget: "",
      headline: "",
    });
    setIsUpdateState(false);
    setSelectedCampaign(null);
  };

  const handleStartDateChange = (newValue) => {
    const formattedStartDate = dayjs(newValue)
      ?.startOf("day")
      ?.format("YYYY-MM-DD");
    if (
      formDataC.endDate &&
      dayjs(formattedStartDate).isAfter(
        dayjs(formDataC.endDate)?.format("YYYY-MM-DD")
      )
    ) {
      setIsDateValid(false);
      toast.error("Start date cannot be greater than end date.");
    }
    setFormDataC({
      ...formDataC,
      startDate: formattedStartDate,
    });
    if (
      formattedStartDate &&
      formDataC.endDate &&
      !dayjs(formattedStartDate).isAfter(
        dayjs(formDataC.endDate)?.format("YYYY-MM-DD")
      )
    ) {
      setIsDateValid(true);
    }
  };

  const handleEndDateChange = (newValue) => {
    const formattedEndDate = dayjs(newValue)
      ?.endOf("day")
      ?.format("YYYY-MM-DD");
    if (
      formDataC.startDate &&
      dayjs(formattedEndDate).isBefore(
        dayjs(formDataC.startDate)?.format("YYYY-MM-DD")
      )
    ) {
      setIsDateValid(false);
      toast.error("End date cannot be before start date.");
    }
    setFormDataC({
      ...formDataC,
      endDate: formattedEndDate,
    });

    if (
      formattedEndDate &&
      formDataC.startDate &&
      !dayjs(formattedEndDate).isBefore(
        dayjs(formDataC.startDate)?.format("YYYY-MM-DD")
      )
    ) {
      setIsDateValid(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isDateValid) {
      toast.error("Start date and end date must be set.");
      return;
    }

    const APIData = {
      ...formDataC,
    };
    console.log(APIData);

    try {
      if (!isUpdateState) {
        const response = await createCampaignAPI(APIData);
        if (response?.status == "success") {
          toast.success(response?.message || "Successfully created");
          getAPI();
        }
      } else {
        const response = await updateCampaignAPI(APIData);
        if (response?.status == "success") {
          toast.success(response?.message || "Successfully created");
          getAPI();
        }
      }

      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleView = (campaign) => {
    setSelectedCampaign(campaign);
    setViewCampInsight(true);
  };
  const handleEdit = (campaign) => {
    setFormDataC(campaign);
    setSelectedCampaign(campaign);
    setIsUpdateState(true);
    handleOpenModal();
  };
  const handleClosePreview = () => {
    setIsPreviewOpen(false);
    setSelectedCampaign(null);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "banner",
      headerName: "Logo",
      width: 80,
      renderCell: (params) => {
        return (
          <div
            className={
              "flex flex-row gap-2 align-center justify-center p-2 items-center"
            }
          >
            {params.row.banner ? (
              <div className="flex items-center justify-center shrink-0">
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row.banner}`}
                  className="w-10 h-10 rounded-sm"
                />
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <img src={UsersIcon} className="w-10 h-10 rounded-full" />
              </div>
            )}
          </div>
        );
      },
    },
    { field: "name", headerName: "Name", width: 100 },
    { field: "headline", headerName: "Headline", width: 120 },
    { field: "description", headerName: "Description", width: 120 },
    {
      field: "maxDiscountAmount",
      headerName: "Max Discount Amount",
      width: 120,
      renderCell: (params) => (
        <p className="text-center">{params?.row?.maxDiscountAmount || "---"}</p>
      ),
    },
    {
      field: "totalBudget",
      headerName: "Total Budget",
      width: 120,
      renderCell: (params) => (
        <p className="text-center">{params?.row?.totalBudget || "---"}</p>
      ),
    },
    {
      field: "transactionCode",
      headerName: "Transaction Code",
      width: 130,
      renderCell: (params) => (
        <p className="text-center">{params?.row?.transactionCode || "---"}</p>
      ),
    },

    {
      field: "discountPercentage",
      headerName: "Discount (%)",
      width: 100,
      renderCell: (params) => (
        <p className="text-center">
          {params?.row?.discountPercentage || "---"}
        </p>
      ),
    },

    {
      field: "startDate",
      headerName: "Start Date (EST)",
      width: 120,
    },
    {
      field: "endDate",
      headerName: "End Date (PST)",
      width: 120,
    },
    {
      field: "view",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <button
          className="bg-blue-500 text-white px-2 shadow-md min-w-[80px] h-auto py-2 rounded inline-flex items-center justify-center"
          onClick={() => handleEdit(params.row)}
          style={{ eight: "nolineHrmal" }}
        >
          Edit
        </button>
      ),
    },
    {
      field: "insight",
      headerName: "Insight",
      width: 120,
      renderCell: (params) => (
        <button
          className="bg-purple-500 text-white px-2 mx-auto shadow-md min-w-[80px] h-auto py-2 rounded inline-flex items-center justify-center"
          onClick={() => handleView(params.row)}
          style={{ lineHeight: "normal" }}
        >
          View Insight
        </button>
      ),
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const data = params.row;
        return (
          <button
            className={classNames(
              ` text-white px-2 mx-auto shadow-md min-w-[80px] h-auto py-2 rounded inline-flex items-center justify-center`,
              { "bg-[#79D32C]": params?.row?.isActive },
              { "bg-[#dd2222]": !params?.row?.isActive },
              { "cursor-not-allowed": params?.row?.status === "Expired" }
            )}
            onClick={() => {
              if (params?.row?.status === "Expired") {
                toast.info("Expired Campaign, please change date range!");
              } else {
                toggleActiveStatus(
                  params?.row,
                  params?.row?.isActive ? false : true
                );
              }
            }}
            style={{ lineHeight: "normal" }}
          >
            {params?.row?.isActive ? "Active" : "Inactive"}
          </button>
        );
      },
    },
    {
      field: "preview",
      headerName: "Preview",
      width: 120,
      renderCell: (params) => {
        const data = params.row;
        return (
          <button
            className="bg-[#2CCCD3] text-white px-2 shadow-md min-w-[80px] h-auto py-2 rounded inline-flex items-center justify-center"
            onClick={() => handlePreview(params?.row)}
            style={{ lineHeight: "normal" }}
          >
            Preview
          </button>
        );
      },
    },
    {
      field: "isEstuFriday",
      headerName: "ESTU Friday",
      width: 110,
      renderCell: (params) => {
        return (
          <div className="flex w-full h-full justify-center items-center">
            <SwitchToggle
              isToggled={params?.row?.isEstuFriday}
              setIsToggled={() => {}}
              onChange={() => handleEstuFridayToggle(params?.row)}
              title="Toggle ESTU Friday Offer"
              rounded={true}
            />
          </div>
        );
      },
    },
  ];

  const handleEstuFridayToggle = async (row) => {
    try {
      const res = await toggleEstuFridayAPI(row?.id, !row?.isEstuFriday); //NOTE:this api is same as update api
      console.log(res);

      if (res?.status === 200) {
        toast.success(
          `Campaign changed to ${
            !row?.isEstuFriday ? "Happy Friday Offer" : "ESTU Exclusive Deal"
          }`
        );
        const updatedData = cData.map((item) =>
          item.id === row.id
            ? { ...item, isEstuFriday: !item.isEstuFriday }
            : item
        );
        setCData(updatedData);
      }
    } catch (error) {
      console.error("Error updating ESTU Friday status:", error);
    }
  };
  const toggleActiveStatus = async (data, newStatus) => {
    try {
      const res = await toggleActiveStatusCampaignAPI(data, newStatus);
      console.log(res);
      if (res?.status) {
        if (newStatus) {
          toast.success(res?.data?.message);

          console.log(cData);
        } else {
          toast.info(res?.data?.message);
        }
        setCData((prevCData) =>
          prevCData.map((item) =>
            item.id === data.id ? { ...item, ...res?.data?.data } : item
          )
        );
      } else {
        toast.error("Failed to update status!");
      }
    } catch (err) {
      console.error(err);
      toast?.error(err);
    }
  };

  return (
    <div className="mx-auto w-full h-full">
      <div className="flex justify-between items-center w-full">
        <h2 className="px-4 text-[#344767] font-roboto font-bold text-2xl leading-[26px]">
          Campaign Management
        </h2>
        <button
          className="bg-[#2cccd3] text-white px-4 py-2 rounded-lg mb-4 cursor-pointer hover:shadow-md"
          onClick={handleOpenModal}
        >
          Create Campaign
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div>Loading...</div>
        </div>
      ) : (
        <Box
          sx={{
            height: "90%",
            width: "100%",
            backgroundColor: "white",
            flex: 1,
            borderRadius: "12px",
          }}
        >
          <CDataGrid
            rows={cData}
            columns={columns}
            loading={loading}
            pagination
          />
        </Box>
      )}

      {openModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50  flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded shadow-lg w-full max-w-lg">
            <h2 className="text-xl font-bold mb-4">
              {viewMode ? "View Campaign" : "Create Campaign"}
            </h2>
            <form className="" onSubmit={handleSubmit}>
              <div className="max-h-[70vh] overflow-y-scroll">
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor="name">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter name"
                    className="w-full p-2 border rounded"
                    value={formDataC.name}
                    onChange={(e) =>
                      setFormDataC({ ...formDataC, name: e.target.value })
                    }
                    disabled={viewMode}
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="headline"
                  >
                    Headline
                  </label>
                  <input
                    type="text"
                    id="headline"
                    placeholder="Enter headline"
                    className="w-full p-2 border rounded"
                    value={formDataC.headline}
                    onChange={(e) =>
                      setFormDataC({
                        ...formDataC,
                        headline: e.target.value,
                      })
                    }
                    disabled={viewMode}
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="description"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    placeholder=""
                    className="w-full p-2 border rounded"
                    value={formDataC.description}
                    rows={4}
                    onChange={(e) =>
                      setFormDataC({
                        ...formDataC,
                        description: e.target.value,
                      })
                    }
                    disabled={viewMode}
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="transactionCode"
                  >
                    Transaction Code
                  </label>
                  <FormControl fullWidth>
                    <InputLabel id="transactionCode-label">
                      Select Transaction Code
                    </InputLabel>
                    <Select
                      labelId="transactionCode-label"
                      id="transactionCode"
                      value={formDataC.transactionCode}
                      label="Select Transaction Code"
                      onChange={(e) =>
                        setFormDataC({
                          ...formDataC,
                          transactionCode: e.target.value,
                        })
                      }
                      disabled={viewMode}
                    >
                      {codes.map((code) => (
                        <MenuItem key={code.id} value={code.id}>
                          {code.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor="banner">
                    Banner Image
                  </label>
                  <input
                    id="banner"
                    type="file"
                    onChange={handleBannerUpload}
                    className={
                      formDataC?.banner
                        ? "hidden"
                        : isUpdateState
                        ? "hidden"
                        : "block"
                    }
                  />
                  {formDataC.banner && (
                    <div
                      className="relative group cursor-pointer mt-4"
                      onClick={() => document.getElementById("banner").click()}
                      style={{ display: "inline-block", maxWidth: "100%" }}
                    >
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASEURL}${formDataC.banner}`}
                        alt="Banner Preview"
                        className="w-full min-h-[80px] min-w-[80px] h-auto rounded-md"
                        style={{ maxHeight: "200px" }} // Restricting max height for the image
                      />

                      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300">
                        <CameraAltRounded
                          className="h-12 w-12 text-white opacity-0
                        group-hover:opacity-100 transition duration-300"
                          fontSize="large"
                          color="white"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="discountPercentage"
                  >
                    Discount Percentage (%)
                  </label>
                  <input
                    type="number"
                    id="discountPercentage"
                    className="w-full p-2 border rounded"
                    value={formDataC.discountPercentage}
                    onChange={(e) =>
                      handleNonNegativeChange(e, (value) =>
                        setFormDataC({
                          ...formDataC,
                          discountPercentage: value,
                        })
                      )
                    }
                    disabled={viewMode}
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="maxDiscountAmount"
                  >
                    Maximum Discount Amount
                  </label>
                  <input
                    type="number"
                    id="maxDiscountAmount"
                    className="w-full p-2 border rounded"
                    value={formDataC.maxDiscountAmount}
                    onChange={(e) =>
                      handleNonNegativeChange(e, (value) =>
                        setFormDataC({
                          ...formDataC,
                          maxDiscountAmount: value,
                        })
                      )
                    }
                    disabled={viewMode}
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="totalBudget"
                  >
                    Total Budget
                  </label>
                  <input
                    type="number"
                    id="totalBudget"
                    className="w-full p-2 border rounded"
                    value={formDataC.totalBudget}
                    onChange={(e) =>
                      handleNonNegativeChange(e, (value) =>
                        setFormDataC({
                          ...formDataC,
                          totalBudget: value,
                        })
                      )
                    }
                    disabled={viewMode}
                  />
                </div>

                <div className="mb-4 flex justify-between items-center gap-3">
                  <DatePicker
                    label="Start Date"
                    value={
                      formDataC.startDate
                        ? dayjs(formDataC.startDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={!isUpdateState ? dayjs() : undefined}
                    maxDate={dayjs("9999-12-31")}
                  />
                  <DatePicker
                    label="End Date"
                    value={formDataC.endDate ? dayjs(formDataC.endDate) : null}
                    onChange={handleEndDateChange}
                    format="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} />}
                    minDate={
                      !isUpdateState
                        ? formDataC.startDate
                          ? dayjs(formDataC.startDate)
                          : dayjs()
                        : undefined
                    }
                    maxDate={dayjs("9999-12-31")}
                  />
                </div>
              </div>
              <div className="flex justify-end bg-white pt-4 border-t-2">
                <button
                  type="button"
                  className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                  onClick={handleCloseModal}
                >
                  {viewMode ? "Close" : "Cancel"}
                </button>
                {!viewMode && (
                  <button
                    type="submit"
                    className="bg-[#2cccd3] text-white px-4 py-2 rounded"
                  >
                    {isUpdateState ? "Update" : "Save"}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}

      {viewCampInsight && (
        <ViewCampaignInsight
          open={viewCampInsight}
          handleClose={() => setViewCampInsight(false)}
          id={selectedCampaign?.id}
        />
      )}

      {isPreviewOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-50  flex justify-center items-center z-50"
          onClick={() => handleClosePreview()}
        >
          <div
            className="relative max-w-sm min-w-[384px] overflow-hidden cursor-pointer p-4 rounded-lg flex flex-col gap-5 bg-white"
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ color: "#FFFFFF", backgroundColor: "#2CCBD1" }}
          >
            {/* Background Image */}
            <div className="absolute inset-0 left-0 top-0 opacity-90 z-10">
              <img
                src={bg_logo_estu}
                alt="Background Logo"
                className="h-full w-[180px]"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className="flex justify-between gap-4 max-h-40 z-20">
              <div className="flex flex-col gap-2 flex-1">
                <p
                  className="font-montserrat text-base font-extrabold line-clamp-2 overflow-hidden text-ellipsis"
                  style={{ color: "#FFFFFF" }}
                  title={selectedCampaign?.headline || "---"}
                >
                  {selectedCampaign?.headline || "---"}
                </p>
                <p
                  className="font-montserrat text-sm font-semibold line-clamp-2 overflow-hidden text-ellipsis"
                  style={{ color: "#FFFFFF" }}
                  title={selectedCampaign?.description || "---"}
                >
                  {selectedCampaign?.description || "---"}
                </p>
              </div>
              <div className="flex flex-col gap-2 w-12 h-12 justify-center items-center self-stretch rounded bg-transparent">
                {selectedCampaign?.banner ? (
                  <img
                    className="w-[48px] h-[48px]  rounded-[10px] inset-2 cursor-pointer hover:scale-105 duration-300"
                    src={`${process.env.REACT_APP_IMAGE_BASEURL}${selectedCampaign.banner}`}
                    alt="logo"
                  />
                ) : (
                  <>
                    <div>
                      <svg
                        className="w-10 h-10 stroke-current text-gray-600"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                          stroke="#687A7D"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                          stroke="#687A7D"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* button start */}
            <div className="flex justify-between items-end relative z-20">
              <button
                className="font-semibold text-xs px-4 py-2 flex-1 rounded-lg shadow-md hover:scale-105 duration-300 active:scale-100"
                style={{ color: "#FFFFFF", backgroundColor: "#58D8DD" }}
              >
                Activate your offer
              </button>
              <img
                src={campaignOffer}
                alt="offer"
                className="w-[80px] h-[80px] -translate-y-1 animate-bounce"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignManagement;
