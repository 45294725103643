import React, { useEffect, useState } from "react";
import StatusBoard from "./statusBoard/StatusBoard";
import { commsContentBoardAPI, commsStatusBoardAPI } from "../../../api/api";
import { toast } from "react-toastify";
import ContentBoard from "./contentBoard/ContentBoard";
import { CircularProgress, Popover, Tooltip, Typography } from "@mui/material";

const CSVDashboard = () => {
  // console.log("CSVDashboard");
  const [statData, setStatData] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(""); // Initial status
  const [csvData, setCsvData] = useState([]);
  const [filteredCsvData, setFilteredCsvData] = useState([]);
  const [loadingContent, setLoadingContent] = useState(false);
  const columns = [
    { field: "email", headerName: "Email", width: 160, download: true },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 120,
      download: true,
    },
    { field: "headline", headerName: "Headline", width: 100, download: true },
    {
      field: "smsMessage",
      headerName: "Message",
      width: 220,
      download: true,
      renderCell: (params) => renderCellWithPopover(params, "smsMessage"),
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 120,
      download: true,
      renderCell: (params) => renderCellWithPopover(params, "subject"),
    },
    {
      field: "emailBody",
      headerName: "Email Body",
      width: 100,
      download: true,
      renderCell: (params) => renderCellWithPopover(params, "emailBody"),
    },
    {
      field: "createdAt",
      headerName: "Sent On",
      width: 100,
      renderCell: (params) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;

        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        const date = new Date(params.value);
        const formattedDate = date.toISOString().split("T")[0]; // YYYY-MM-DD
        const fullDateTime = date.toString(); // Full date and time

        return (
          <div>
            <Typography
              aria-describedby={id}
              variant="body2"
              onClick={handleClick}
              // onMouseOver={handleClick}
              style={{ cursor: "pointer" }}
            >
              {formattedDate}
            </Typography>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{ maxWidth: 600 }}
            >
              <Typography sx={{ p: 2, maxWidth: 600, wordWrap: "break-word" }}>
                {fullDateTime}
              </Typography>
            </Popover>
          </div>
        );
      },
    },
    { field: "image", headerName: "Image", width: "80", download: true },
    {
      field: "fileName",
      headerName: "File Name",
      width: 150,
      renderCell: (params) => renderCellWithPopover(params, "fileName"),
    },
    { field: "status", headerName: "Status", width: "90", download: true },
  ];
  const renderCellWithPopover = (params, field) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? `simple-popover-${field}` : undefined;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const fullValue = params.value; // Full value to display in the popover

    return (
      <div
        style={{
          cursor: "pointer",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Typography
          aria-describedby={id}
          variant="body2"
          onClick={handleClick}
          style={{
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          className="truncate px-2 whitespace-nowrap mx-3"
        >
          {fullValue}
        </Typography>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ maxWidth: 400 }}
        >
          <Typography sx={{ p: 2, maxWidth: 400, wordWrap: "break-word" }}>
            {fullValue}
          </Typography>
        </Popover>
      </div>
    );
  };

  const getAPI = async () => {
    try {
      const response = await commsStatusBoardAPI();

      if (response.status == "success") {
        setStatData(response || {});
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getContentAPI = async (status) => {
    setLoadingContent(true);
    try {
      const response = await commsContentBoardAPI({});
      setCsvData(response?.data || []);
      setFilteredCsvData(response?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingContent(false);
    }
  };
  useEffect(() => {
    getAPI();
    getContentAPI(selectedStatus);
  }, []);
  useEffect(() => {
    if (selectedStatus) {
      setFilteredCsvData(
        csvData.filter((item) => item?.status?.toUpperCase() === selectedStatus)
      );
    } else {
      setFilteredCsvData(csvData);
    }
  }, [selectedStatus]);
  return (
    <div className="flex flex-col h-full gap-[27px]">
      <StatusBoard
        data={statData}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />

      <ContentBoard
        stats={statData}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        // csvData={csvData}
        csvData={filteredCsvData}
        columns={columns}
        loading={loadingContent}
      />
    </div>
  );
};

export default CSVDashboard;
