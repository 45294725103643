import React, { useEffect, useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { getStudentReportsAPI } from "../../api/api";
import SearchBox from "../../shared/SearchBoxes/SearchBox";
import DownloadCSV from "../../shared/downloadCSV/DownloadCSV";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import classNames from "classnames";
import { isValidDate } from "../../utils/func";
import { columns, tabs } from "./config";
import CustomerReportTab from "../../components/dashboard/CustomerReportTab";
import { useSearchParams } from "react-router-dom";
import { Loader } from "../../components";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";

const CustomerReportsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newParams = new URLSearchParams(searchParams);
  let stateActiveTab = newParams.get("type") || tabs[0].value;
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(stateActiveTab);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(0);

  const dynamicColumns = useMemo(() => {
    if (activeTab === tabs[0].value) {
      return [
        ...columns,
        {
          field: "type",
          headerName: "Type",
          width: 130,
          download: true,
          renderCell: (params) => (
            <p className="text-start w-full">{params?.row?.type || "---"}</p>
          ),
        },
      ];
    }
    return columns;
  }, [activeTab]);

  useEffect(() => {
    getAPI();
  }, [activeTab, page]);

  const titleData = useMemo(() => {
    return tabs.find((tab) => tab.value === activeTab);
  }, [activeTab, tabs]);

  const setTab = (type) => {
    setActiveTab(type);
    setPage(0);
    setSearchParams({ type });
  };

  const getAPI = async () => {
    setLoading(true);
    try {
      const response = await getStudentReportsAPI({
        type: activeTab,

        page: page + 1,
      });

      const data = response?.data || [];
      setData(data);
      setFilteredData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    let filtered = data;

    if (searchQuery) {
      filtered = filtered.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    if (isValidDate(startDate)) {
      const startTimestamp = startDate
        ? new Date(dayjs(startDate).startOf("day").toISOString()).getTime()
        : null;

      if (startTimestamp) {
        filtered = filtered.filter((item) => {
          const createdAt = new Date(item.createdAt).getTime();
          return createdAt >= startTimestamp;
        });
      }
    }
    if (isValidDate(endDate)) {
      const endTimestamp = endDate
        ? new Date(dayjs(endDate).endOf("day").toISOString()).getTime()
        : null;

      if (endTimestamp) {
        filtered = filtered.filter((item) => {
          const createdAt = new Date(item.createdAt).getTime();
          return createdAt <= endTimestamp;
        });
      }
    }

    setFilteredData(filtered);
  };

  useEffect(() => {
    handleSearch();
  }, [startDate, endDate, searchQuery, data]);

  return (
    <div className="px-4 flex flex-col h-full">
      {/* <Box className="px-4 py-4 flex flex-col*/}
      <div className="flex justify-between items-center">
        <div className={`flex flex-row gap-2 items-center`}>
          {tabs.map((tab, index) => (
            <CustomerReportTab
              setActiveTab={() => setTab(tab.value)}
              activeTab={activeTab}
              tab={tab}
            />
          ))}
        </div>
        <div className="flex justify-center items-center gap-4">
          <div
            className={classNames(`flex gap-2`, {
              hidden: isSearchActive,
            })}
          >
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              slotProps={{ textField: { size: "small" } }}
              format="DD/MM/YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              slotProps={{ textField: { size: "small" } }}
              format="DD/MM/YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div className="relative">
            <SearchBox
              onSearch={(query) => setSearchQuery(query)}
              placeholder="Search something..."
              data={data}
              active={isSearchActive}
              setActive={setIsSearchActive}
            />
          </div>
          <div className={classNames(`flex gap-2`)}>
            <DownloadCSV
              fileName={"estu-customer-report"}
              columns={dynamicColumns}
              data={filteredData}
              fieldsToInclude={[
                "id",
                "name",
                "email",
                "collegeName",
                "mobileNo",
                "createdAt",
                "savingAccountNumber",
                "clientId",
                "cardId",
                "bankOnboardingDate",
                "classification",
                ...(activeTab === "all" ? ["type"] : []),
              ]}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "whitespace-nowrap mt-3 py-2 px-4 mb-4 bg-white rounded-lg flex flex-row justify-start items-center border-b gap-1 font-montserrat text-[16px] font-semibold leading-4 [letter-spacing:_0.34px] text-center transition-[0.2s_all_ease-in-out]"
        )}
      >
        {titleData?.title}
      </div>
      {/* <hr /> */}
      <Box className="bg-white rounded-lg shadow-md p-4 flex-1">
        <>
          {filteredData?.length === 0 && loading ? (
            <Loader />
          ) : filteredData?.length > 0 ? (
            <CDataGrid
              rows={filteredData}
              columns={dynamicColumns}
              pagination
              page={page}
              loading={loading}
              className={"bg-white"}
            />
          ) : null}
        </>
      </Box>
      {/* </Box> */}
    </div>
  );
};

export default CustomerReportsPage;
