import { useStateContext } from "../../../contexts/ContextProvider";

const MenuChip = ({ navigate, url, name, icon }) => {
  const { menuToggle } = useStateContext();

  return (
    <div
      onClick={() => navigate(url)}
      className={` ${
        window?.location?.pathname === url ? "bg-white shadow-lg " : ""
      } flex items-center py-[11px] cursor-pointer hover:bg-[#e9eeef] hover:shadow-lg rounded-[8px] whitespace-nowrap w-full
    ${menuToggle ? "pl-[16px]" : " justify-center"}`}
    >
      {icon && (
        <div
          title={name}
          className={`${
            window?.location?.pathname === url
              ? "bg-[#2CCCD3] text-white"
              : "text-black"
          }  rounded-[8px] shadow p-[10px] `}
        >
          {icon ? icon : null}
        </div>
      )}
      {menuToggle && <div className="pl-[14px] pr-2 w-fit">{name}</div>}
    </div>
  );
};

export default MenuChip;
