import CottageIcon from "@mui/icons-material/Cottage";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import {
  AdUnits as AdUnitsIcon,
  Group as GroupIcon,
  School as SchoolIcon,
  Public as PublicIcon,
  LocalOffer as LocalOfferIcon,
  LocalMall as LocalMallIcon,
  ErrorOutline as ErrorOutlineIcon,
  VerifiedUser as VerifiedUserIcon,
  AccountBalance as AccountBalanceIcon,
  PersonOff as PersonOffIcon,
  Email as EmailIcon,
  ReportGmailerrorredTwoTone,
  ScaleOutlined,
  ReportOffRounded,
} from "@mui/icons-material";
const menuItems = [
  {
    url: "/dashboard",
    name: "Dashboard",
    icon: <CottageIcon />,
    department: ["ADMIN"],
  },
  {
    url: "/customer-reports",
    name: "Customer Reports",
    icon: <ReportGmailerrorredTwoTone />,
    department: ["ADMIN"],
  },

  {
    icon: <AdUnitsIcon />,
    url: "/ads",
    name: "Ads Manager",
    department: ["ADMIN"],
  },
  {
    url: "/estu-ads",
    icon: <GroupIcon />,
    name: "Partners",
    department: ["ADMIN"],
  },
  {
    icon: <LocalOfferIcon />,
    url: "/coupons-management",
    name: "Coupons & Events",
    department: ["ADMIN"],
  },
  {
    icon: <LocalMallIcon />,
    url: "/deals",
    name: "Deals",
    department: ["ADMIN"],
  },
  {
    icon: <NewspaperIcon />,
    url: "/media",
    name: "Media",
    department: ["ADMIN"],
  },
  {
    icon: <ReportOffRounded />,
    url: "/referrals",
    name: "Referrals",
    department: ["ADMIN"],
  },

  {
    icon: <ErrorOutlineIcon />,
    url: "/kycerrors",
    name: "KYC Errors",
    department: ["ADMIN"],
  },
  {
    icon: <ScaleOutlined />,
    url: "/campaigns-management",
    name: "Campaigns",
    department: ["ADMIN"],
  },
  {
    icon: <PersonOffIcon />,
    url: "/international-student",
    name: "International Student",
    department: ["ADMIN"],
  },
  {
    icon: <VerifiedUserIcon />,
    url: "/allowedUsers",
    name: "Allowed Users",
    department: ["ADMIN"],
  },
  {
    icon: <AccountBalanceIcon />,
    url: "/mbanq",
    name: "MBanq",
    department: ["ADMIN"],
  },

  {
    // icon: <AdsIcon />,
    icon: <EmailIcon />,
    url: "/comms",
    name: "COMMS",
    department: ["ADMIN", "MARKETING"],
  },
  // {
  //   url: "/waitlist",
  //   name: "Wait List",
  //   icon: <ListAltIcon />,
  //   department: ["ADMIN"],
  // },
  {
    icon: <SchoolIcon />,
    url: "/instituteList",
    name: "Institute List",
    department: ["ADMIN"],
  },
  {
    icon: <PublicIcon />,
    url: "/countryList",
    name: "Country List",
    department: ["ADMIN"],
  },
];

export { menuItems };
