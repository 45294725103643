import classNames from "classnames";

const CustomerReportTab = ({ setActiveTab, activeTab, tab }) => {
  return (
    <div
      title={tab.title || ""}
      onClick={(_) => setActiveTab(tab.value)}
      className={classNames(
        "cursor-pointer w-fit h-[43px] whitespace-nowrap py-3 px-1 flex flex-row justify-center items-center border-b gap-1 text-[#67748e] font-montserrat text-[14px] font-semibold leading-4 [letter-spacing:_0.3428399860858917px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
        {
          "font-montserrat border-b border-b-[#67748e] text-base transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.3428399860858917px;] text-center":
            activeTab === tab.value,
        }
      )}
    >
      {tab.text}
    </div>
  );
};

export default CustomerReportTab;
