import React, { useCallback, useState } from "react";
import helpIcon from "../../assets/svgs/helpIcon.svg";
import { useStateContext } from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import { Logo, LogoutIcon } from "./../../assets/svgs/waitlist";
import { useAuth } from "../../contexts/AuthContext";
import { menuItems } from "./config";
import MenuChip from "./sidebar/MenuChip";
import { GridMenuIcon } from "@mui/x-data-grid";
import classNames from "classnames";

const Sidebar = () => {
  const { menuToggle, setMenuToggle } = useStateContext();
  const { userDepartment } = useAuth();
  const navigate = useNavigate();
  const department = userDepartment();

  const handleMenuClick = useCallback(() => {
    if (menuToggle !== undefined) {
      setMenuToggle(!menuToggle);
    }
  }, [menuToggle]);

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.reload();
  }, []);

  return (
    <div
      className={classNames(
        `py-6 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto hover:no-scrollbar pb-10 
      ${menuToggle ? "w-[264px]" : "w-[80px]"}`
      )}
    >
      {/* starts */}
      <div className=" flex justify-start px-2 gap-10  items-center">
        <span
          className="ml-[16px] cursor-pointer hover:bg-black hover:bg-opacity-5 duration-200 transition-all rounded-full p-1"
          onClick={handleMenuClick}
        >
          <GridMenuIcon />
        </span>
        {menuToggle && <Logo />}
      </div>
      <div className="sidebar-line"></div>

      <div className="px-2 pb-4 w-fit">
        {menuItems
          ?.filter((menu) => menu?.department?.includes(department))
          ?.map((menu, idx) => (
            <MenuChip
              key={idx}
              navigate={navigate}
              url={menu?.url}
              name={menu?.name}
              icon={menu?.icon}
            />
          ))}
        <button
          onClick={() => logout()}
          className={`items-center py-[11px] p-3 mt-2 hover:shadow-md cursor-pointer flex rounded-[8px] group hover:bg-red-400 transition-all duration-300 hover:text-white w-full
             ${menuToggle ? "pl-[16px]" : " justify-center"}`}
        >
          <div
            className={`text-black rounded-[8px] shadow p-2  group-hover:bg-white group-hover:!text-white`}
          >
            <LogoutIcon />
          </div>
          {menuToggle && (
            <div className="pl-[14px] text-red-400 group-hover:text-white">
              Logout
            </div>
          )}
        </button>
      </div>
      {/* ends */}
    </div>
  );
};

export default Sidebar;
